import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { Flex, Spacer } from '@kanvas/andromeda';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { TextTransform } from 'react-native-localized-text';
import MIcon from 'react-native-vector-icons/MaterialIcons';
import { ActivityIndicator, FlatList, Linking, ListRenderItemInfo, Modal } from 'react-native';
import Toast from "react-native-toast-message";

// model
import Task, { OptionDoc, Status, Type } from 'model/data/task';
import TaskPresenter from 'model/presenters/task.presenter';
import { TaskList } from 'model/api/task-list/queries';
import TaskListApi from 'model/api/task-list/task-list.api';

// Constants
import colors from 'theme/colors';
import { translate } from 'locales';
import { ACTION_ENGINE_FILE_VIEWER } from 'env';

// Domain
import wait from 'domain/shared/utils/wait';
import { RootState } from 'domain/shared/store';
import { CONTENT_PREVIEW } from 'domain/shared/screens';
import { LeadAction } from 'domain/shared/types/lead-action';
import { File as FSFile, UploadFile } from 'domain/shared/types/file.interface';
import { IS_CHROME_EXTENSION, IS_DESKTOP_WEB, IS_MOBILE, IS_MOBILE_WEB, IS_WEB } from 'domain/shared/constants';
import OpportunityService from 'domain/shared/services/opportunity-service';
import { setActiveOpportunity } from 'domain/shared/store/slice/opportunity';
import { OpenInAppModalBrowser } from 'domain/shared/utils/openInAppBrowser';
import CRMExtensionService from 'domain/shared/services/crm-extension-service';
import usePickFile from 'domain/opportunity-wall/screens/content-preview/hooks/use-pick-file';
import { showSuccess } from 'domain/shared/utils/toast';
import CustomFieldsApi from 'domain/shared/services/custom-fields';
import { useCompanySettings } from 'domain/shared/hooks/useAppSettings';
import { ScannerParams } from 'domain/create-opportunity/hooks/use-scanner-screen';

// Components
import ButtonWithIcon from 'components/atoms/button-with-icon';
import { Body3, Meta2 } from 'components/molecules/text';
import TaskItem from 'components/molecules/task-item';
import TaskButton from 'components/molecules/task-button';
import ChecklistModal from 'components/molecules/checklist-modal';
import ChecklistButton from 'components/atoms/checklist-button';
import ChangeChecklistModal from 'components/molecules/change-checklist-modal';
import CheckboxItem from 'components/molecules/multiple-checkbox-item';
import OfacModal, { OfacModalRef } from 'components/molecules/ofac-modal';

// Screens
import { OpportunityScreens } from 'domain/opportunity-list/screens';

// Bad Practice Components
import UploadLeadFiles, { UploadLeadFilesRef } from '../upload-lead-files';
import Spinner from 'components/atoms/spinner';
import PullCreditModal, { PullCreditModalRef } from 'components/molecules/pullcredit-modal';
import Sentry from 'sentry';
import { getPeopleFiles } from 'domain/shared/graphql/mutations/associate-files-to-people';
import client from 'domain/shared/graphql/client';
import ChecklistComplete, { playChecklistComplete } from 'components/atoms/lottie/checklist-complete';
import checklistCompleted from 'model/use-cases/checklist-completed';
import AddParticipantModal from '../share-modal/add-participant-modal';
import { FONTS } from 'theme/typography';

interface Props {
  leadId: string;
}

function useWallCheckList(props: Props) {
  const [loading, setLoading] = useState(true);

  const [changeChecklistModalOpen, setChangeChecklistModalOpen] = useState(false);

  const [item, setItem] = useState<Task | null>(null);
  const [items, setItems] = useState<Task[]>([]);
  
  const [optional, setOptional] = useState(false);
  const [shareable, setShareable] = useState(false);
  const [idVerification, setIdVerification] = useState(false);
  const [turnauto, setTurnauto] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [pullCredit, setPullCredit] = useState(false);
  const [showParticipantModal, setShowParticipantModal] = useState(false);
  const [pullCreditProviders, setPullCreditProviders] = useState<string[]>([]);
  const [ofacStatus, setOfacStatus] = useState<'success'>();
  const [pullCreditStatus, setPullCreditStatus] = useState<'success'>();
  const [cobuyerSignDocs, setCobuyerSignDocs] = useState(false);
  const [selectedCobuyerId, setSelectedCobuyerId] = useState<string>();
  const [showModalMsg, setShowModalMsg] = useState(IS_WEB);

  const [formSheet, setFormSheet] = useState<LeadAction[]>([]);
  const [optionsDocs, setOptionsDocs] = useState<OptionDoc[]>([]);

  const [activeTaskList, setActiveTaskList] = useState<{ mode: string, activeTaskListId: number }>();
  const [taskLists, setTaskLists] = useState<TaskList[]>([]);

  const pullCreditModal = useRef<PullCreditModalRef>(null);

  const { activeOpportunity } = useSelector((state: RootState) => state.opportunity);
  const companySettings = useCompanySettings();

  // Refs
  const ofacModalRef = useRef<OfacModalRef>(null);

  const id = activeOpportunity?.id;
  const uuid = activeOpportunity?.uuid;

  const presenter = useMemo(() => new TaskPresenter({
    get: async (items) => {
      // i don't get it but it does refresh the data like this if the subscription refresh it
      setItems([]);
      setItems([...items]);

      if (checklistCompleted(items)) playChecklistComplete();

      setLoading(false);
    },
    error: () => {
      setLoading(false);
    },
  }), [uuid, id]);

  async function fetchTaskList() {
    try {
      const taskListResponse = await TaskListApi.get()
      const { customFields } = await CustomFieldsApi.get({ entity_id: uuid, name: 'check_list_status' });
      
      const activeOne = { mode: "automatic", activeTaskListId: taskListResponse[0]?.id };
      const activeTaskList = (customFields.check_list_status ?? activeOne) as { mode: string, activeTaskListId: number };
      
      setTaskLists(taskListResponse ?? [])
      setActiveTaskList(activeTaskList)

      return { taskLists: taskListResponse, activeTaskList };
    } catch {
      Sentry.captureException(new Error(`Task List failed to load for lead ${uuid}`));
      return undefined;
    }
  }

  const activeChecklistName = useMemo(() => taskLists?.find(list => list.id.toString() === activeTaskList?.activeTaskListId.toString())?.name, [activeTaskList, taskLists]);

  const vehicleType = useMemo(() => {
    if (!activeOpportunity) return null;

    const { vehicle_of_interest = null } = activeOpportunity?.custom_fields as Record<string, any> || {} as Record<string, any>;
    if (!vehicle_of_interest) return undefined;

    const { isNew } = vehicle_of_interest;

    return isNew ? 'New' : 'Used';
  }, [activeOpportunity]);

  const changeChecklistMessage = useMemo(() => {
    let message = translate('checklist.changeChecklistDefaultMessage');

    if (activeTaskList?.mode === "automatic" && vehicleType) {
      message = `${translate('checklist.primaryVehicleCheckMessage', undefined, {
        interpolate: {
          vehicleType: vehicleType
        }
      })} ${translate('checklist.changeChecklistDefaultMessage')}`
    }

    return message;
  }, [vehicleType, activeTaskList]);

  const uploadRef = useRef<UploadLeadFilesRef>(null);

  const { handleUploadFilePromise } = usePickFile();

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const changeActiveChecklist = async (taskListId: number) => {
    setLoading(true);
    setChangeChecklistModalOpen(false);

    if (!id) return setLoading(false);

    try {
      const data = { mode: "manual", activeTaskListId: taskListId }
      await CustomFieldsApi.set({ entity_id: activeOpportunity!.uuid, name: 'check_list_status', data });
      setActiveTaskList(data);

      presenter.fetch(id, taskListId)
    } catch(err) {
      console.log(err)
    }
  }

  const toggleChecklistModalOpen = () => setChangeChecklistModalOpen(!changeChecklistModalOpen);

  const openPreview = (item: Task, action?: LeadAction) => {
    setFormSheet([]);

    const { 
      slug,
      name,
      id = 0,
      description,
      form_config,
    } = action || item.config.action;

    const props = {
      id,
      title: name,
      description,
      form_config,
      key: slug,
      contact: activeOpportunity,
      selected: item.config.item,
      should_open_in_browser: false,
      multiSelect: item.type === Type.LIST,
    }

    // @ts-ignore
    navigation.navigate(CONTENT_PREVIEW, { props });
  }

  const handleForm = (item: Task) => {
    if (!item.config.options) {
      openPreview(item);
      return;
    }
    
    setFormSheet(item.config.options);
  };

  const handleCloseForm = () => {
    setFormSheet([]);
    setOptionsDocs([]);
    setPullCredit(false);
    setPullCreditProviders([]);
  };

  const reloadScreen = () => {
    setLoading(true);
    presenter.fetch(activeOpportunity!.id, activeTaskList?.activeTaskListId);
  }

  // TODO: can be refactor
  const openFileViewer = (leadUuid: string, messageUuid: string, title?: string) => {
    const url = `${ACTION_ENGINE_FILE_VIEWER}?lid=${leadUuid}&mid=${messageUuid}`;

    if (IS_MOBILE_WEB || IS_MOBILE) {
      OpenInAppModalBrowser(url);
      return;
    }

    if (IS_CHROME_EXTENSION) {
      CRMExtensionService.openFileViewer(url, title || '');
      return;
    }


    if (IS_DESKTOP_WEB) {
      Linking.openURL(url);
      return;
    }
  }

  // TODO: it need to be refactor in the future
  const getFileFromWeb = async (item: Task) => {
    try {
      if (!activeOpportunity) return;
      
      const { files = [] } = activeOpportunity;
      
      const name = item!.title.split('').map((item, index) => index === 0 ? item.toUpperCase() : item).join('').split(' ').join('_');
      const sequence = files.filter((file: FSFile) => file.field_name?.includes(name)).length + 1;
      const completeName = `${name}_${sequence.toString().padStart(3, '0')}`;

      const file = await handleUploadFilePromise();
      setFileUpload(true);
      const extension = file.name.split('.').slice(-1);
      const [response] = await OpportunityService.uploadPhoto(file, completeName, `${completeName}.${extension}`);

      const { id, title } = activeOpportunity;
      const newFiles = [...files, { field_name: completeName, filesystem_id: response.id, id: 0, title }] as any;
      const opp = await OpportunityService.associateFileToOpp(newFiles, id, title, item.id);

      dispatch(setActiveOpportunity(opp));
      reloadScreen();

      setFileUpload(false);
      await wait(400);
      showSuccess(`${completeName} ${translate('uploadMessage')}`);
    } catch (err: any) {
      console.log(err);
    }
  }

  const uploadCobuyerWeb = async (item: Task, cobuyerId: string) => {
    try {
      if (!activeOpportunity) return;
        
      const { files = [] } = activeOpportunity;
      
      const formattedName = item!.title.split('').map((item, index) => index === 0 ? item.toUpperCase() : item).join('').split(' ').join('_');
      
      // Updade file name for cobuyer
      const cobuyerFiles = await client.query<{ peoples: {data: any[]} }>({
        query: getPeopleFiles,
        variables: { id: cobuyerId },
        fetchPolicy: 'no-cache'
      });

      const formatFiles = cobuyerFiles.data?.peoples?.data[0]?.files?.data?.map((file: any) => ({ field_name: file?.field_name }))

      const cobuyerSequence = formatFiles.filter((fs: any) => (
        fs.field_name?.includes(formattedName)
      )).length + 1;

      const name = `${formattedName}_${cobuyerSequence.toString().padStart(3, '0')}`

      const file = await handleUploadFilePromise() as unknown as File;

      const { id, title } = activeOpportunity;

      const formattedFile: File = new File([file], name, { type: file.type });

      const opp = await OpportunityService.associateFileToOpp(
        [...files] as any,
        id,
        title,
        item?.id,
        cobuyerId,
        [formattedFile]
      );

      await presenter.changeStatusToCompleted(activeOpportunity!.id, item.id, activeTaskList?.activeTaskListId, undefined, {
        people_id: cobuyerId,
        file_upload: true
      });

      if (opp) dispatch(setActiveOpportunity(opp));
      
      await wait(400);

      reloadScreen();

      setFileUpload(false);
      showSuccess(`${name} ${translate('uploadMessage')}`);
    } catch(err) {
      console.log(err)
      setFileUpload(false)
    }
  }

  const handleCamera = async (item: Task, cobuyerId?: any) => {
    await wait(200);

    if (IS_WEB) {
      if (item?.config?.cobuyerSignDocs) {
        if (!cobuyerId) return;

        await uploadCobuyerWeb(item, cobuyerId)

        return;
      }

      getFileFromWeb(item);
      return;
    }

    uploadRef.current?.openCamera();
  }

  const handleVerified = async (showRoom: boolean) => {
    setIdVerification(false);

    if (showRoom) {
      // Open Scandit Scanner

      wait(1000).then(() => {
        const hadIdScannerAccess = !!companySettings?.id_verification;
        const scanParams: ScannerParams = { type: 'wall' };
        const { SCANNER, ID_SCANNER } = OpportunityScreens;
        // @ts-ignore
        navigation.navigate(hadIdScannerAccess ? ID_SCANNER : SCANNER, scanParams);
      });
    } else {
      // Open Remote ID verification

      if (!item) return;
      openPreview(item)
    }
  }

  const handleTurnauto = async (open: boolean) => {
    setTurnauto(false);

    if (!item) return;

    if (open) {
      // Open turnauto app

      const TURNAUTO_DEEPLINK = "https://me-qr.com/yOGHYivu";

      const supported = await Linking.canOpenURL(TURNAUTO_DEEPLINK);

      if (supported) {
        await Linking.openURL(TURNAUTO_DEEPLINK);
      } else {
        Toast.show({
          text1: translate('turnautoError'),
          type: 'error',
        })
        console.log("Don't know how to open URI: ", TURNAUTO_DEEPLINK);
      }
    } else {
      // Complete turnauto action
      setLoading(true);
      await presenter.changeStatusToCompleted(activeOpportunity!.id, item.id, activeTaskList?.activeTaskListId);
      setOfacStatus(undefined);
      presenter.fetch(activeOpportunity!.id, activeTaskList?.activeTaskListId);

    }
  }

  const handleOfac = async (messageId?: number) => {
    if (ofacStatus === 'success' && item) {
      setLoading(true);
      await presenter.changeStatusToCompleted(activeOpportunity!.id, item.id, activeTaskList?.activeTaskListId, messageId);
      setOfacStatus(undefined);
      presenter.fetch(activeOpportunity!.id, activeTaskList?.activeTaskListId);
    }
  }

  const executeStrategy = (item: Task) => {
    const strategy = {
      [Type.LIST.toString()]: () => setShareable(true),
      [Type.PULL_CREDIT.toString()]: () => setPullCredit(true),
      [Type.FORM.toString()]: () => handleForm(item),
      [Type.CAMERA.toString()]: () => handleCamera(item),
      [Type.OFAC.toString()]: () => ofacModalRef.current?.execute(props.leadId),
      [Type.ID_VERIFICATION.toString()]: () => setIdVerification(true),
      [Type.TURNAUTO.toString()]: () => setTurnauto(true),
      default: () => openPreview(item),
    }

    const execute = strategy[item.type.toString()] || strategy.default;

    execute();
  }

  const handleFinishPullCredit = async (pullCreditId?: number) => {
    if (pullCreditStatus === 'success' && item) {
      setLoading(true);
      await presenter.changeStatusToCompleted(activeOpportunity!.id, item.id, activeTaskList?.activeTaskListId, pullCreditId);
      setPullCreditStatus(undefined)
      setPullCreditProviders([]);
      presenter.fetch(activeOpportunity!.id, activeTaskList?.activeTaskListId);
    }
  }

  const handlePullCredit = async () => {
    setPullCredit(false);
    await wait(400)
    pullCreditModal.current?.execute(props.leadId, pullCreditProviders);
  }

  const handleCheckProvider = useCallback((provider: string) => {
    if (pullCreditProviders.find((item) => item === provider)) {
      setPullCreditProviders(pullCreditProviders.filter((item) => item !== provider));
      return;
    }

    setPullCreditProviders([...pullCreditProviders, provider]);
  }, [pullCreditProviders]);

  const handleOptionDoc = (option: OptionDoc) => {
    if (!item) return;

    setOptionsDocs([]);
    const { id } = option;
    const itemTask = {
      ...item,
      config: {
        ...item.config,
        item: id.toString(),
      }
    }
    
    openPreview(itemTask)
  }

  const handleItemPress = async (item: Task, redo?: boolean) => {
    setItem(item);
    const { optional, direct, screen, messageUuid, optionsDocs, iframe_url_signed } = item.config;

    if (item.status === Status.COMPLETED && iframe_url_signed && item.type === Type.PULL_CREDIT && !redo) {
      // Pull-Credit specific logic for 700-credit

      if (IS_MOBILE_WEB || IS_MOBILE) {
        OpenInAppModalBrowser(iframe_url_signed);
        return;
      }
  
      if (IS_CHROME_EXTENSION) {
        const leadName = activeOpportunity?.firstname + ' ' + activeOpportunity?.lastname;

        CRMExtensionService.openLinkOnModal(iframe_url_signed, leadName || '');
  
        return;
      }
  
      if (IS_DESKTOP_WEB) {
        Linking.openURL(iframe_url_signed);
        return;
      }

      return;
    }

    if (item.status === Status.COMPLETED && messageUuid && !redo) {
      openFileViewer(activeOpportunity!.uuid, messageUuid, item.title);
      return;
    }

    if (!!optionsDocs) {
      setOptionsDocs(optionsDocs);
      return;
    }

    if (optional) {
      setOptional(true);
      return;
    }

    if (direct) {
      openPreview(item);
      return;
    }

    if (screen) {
      // @ts-ignore
      navigation.navigate(screen, { action: item.config.action, onGoBack: async () => {
        setLoading(true);
        await wait(400);
        reloadScreen();
      } });
      return;
    }

    executeStrategy(item);
  };

  const handleAction = async (action: 'remote' | 'upload') => {
    setShareable(false);
    await wait(400)

    const strategy = {
      'remote': () => {
        if (!item) return;
        openPreview(item)
      },
      'upload': async () => {
        if (!item) return;
        if (item.config.cobuyerSignDocs) {
          if (!activeOpportunity?.participants || activeOpportunity?.participants?.length <= 0) {
            setShowParticipantModal(true)
            return;
          };

          setCobuyerSignDocs(true);
          return;
        }

        await handleCamera(item!)
      },
    }
    strategy[action]?.();
  };

  const onCloseParticipant = async () => {
    setShowParticipantModal(false)
  }

  const onPopUpMsgClose = () => {
    setShowModalMsg(false)
  }

  const handleFileSelected = async () => {
    if (item) {
      await presenter.changeStatusToCompleted(activeOpportunity!.id, item.id, activeTaskList?.activeTaskListId, undefined, {
        people_id: selectedCobuyerId,
        file_upload: true
      });
    }

    // Making the app a bit slower just for better EX
    wait(400).then(() => reloadScreen())
  };

  const onSubmitParticipant = useCallback(async (participant: any) => {
    if (activeOpportunity?.participants) {
      dispatch(setActiveOpportunity({
        ...activeOpportunity,
        participants: [
          ...activeOpportunity.participants,
          participant
        ]
      }));
    } else if (activeOpportunity) {
      dispatch(setActiveOpportunity({
        ...activeOpportunity,
        participants: [
          participant
        ]
      }));
    }

    onCloseParticipant();

    await wait(400);

    setCobuyerSignDocs(true);
  }, [activeOpportunity]);

  const handleOptional = async (yes?: boolean) => {
    setOptional(false);
    if (!item) return;
    if (!yes) {
      setLoading(true);
      presenter.changeStatusToNoApplicable(activeOpportunity!.id, item.id, activeTaskList?.activeTaskListId);
      return;
    }

    const { screen, optional, direct } = item.config;

    if (screen) {
      // @ts-ignore
      navigation.navigate(screen, { action: item.config.action, onGoBack: async () => {
        setLoading(true);
        await wait(400);
        reloadScreen();
      }  });
      return;
    }

    if (optional && !direct) {
      wait(400).then(() => executeStrategy(item))
      return;
    }

    openPreview(item);
  };

  const handleCobuyerUpload = async (cobuyerId: string) => {
    setCobuyerSignDocs(false)
    setSelectedCobuyerId(cobuyerId)
    await handleCamera(item!, cobuyerId)
  }

  const closeModal = () => {
    setItem(null);
    setShareable(false);
    setOptional(false);
    setIdVerification(false);
    setTurnauto(false);
    setCobuyerSignDocs(false);
    setSelectedCobuyerId(undefined);
  };

  useFocusEffect(
    useCallback(() => {
      if (!id || !uuid) return;

      let unsubscribe: null | VoidFunction = null

      fetchTaskList().then(function (response) {
        if (typeof response === 'undefined') {
          console.log('task list not fetched');
          return;
        }

        presenter.fetch(id, response?.activeTaskList?.activeTaskListId)
        unsubscribe = presenter.connect(id, activeTaskList?.activeTaskListId);
      });


      return () => {
        setLoading(true);
        setItems([]);
        setTaskLists([]);
        setActiveTaskList(undefined);
        unsubscribe?.();
      }
    }, [id, uuid])
  );


  return {
    model: {
      item,
      items,
      loading,
      optional,
      shareable,
      uploadRef,
      activeOpportunity,
      formSheet,
      fileUpload,
      optionsDocs,
      changeChecklistModalOpen,
      activeChecklistName,
      activeTaskList,
      taskLists,
      changeChecklistMessage,
      pullCreditModal,
      pullCredit,
      pullCreditProviders,
      ofacModalRef,
      idVerification,
      turnauto,
      cobuyerSignDocs,
      selectedCobuyerId,
      showParticipantModal,
      showModalMsg
    },
    operations: {
      closeModal,
      handleItemPress,
      handleOptional,
      handleAction,
      handleFileSelected,
      reloadScreen,
      handleCloseForm,
      openPreview,
      handleOptionDoc,
      toggleChecklistModalOpen,
      changeActiveChecklist,
      handleVerified,
      handleTurnauto,
      setOfacStatus,
      handleOfac,
      setPullCreditStatus,
      handleFinishPullCredit,
      handlePullCredit,
      handleCheckProvider,
      handleCobuyerUpload,
      onCloseParticipant,
      onSubmitParticipant,
      onPopUpMsgClose
    }
  };
}

export default function WallCheckList(props: Props) {
  const { model, operations } = useWallCheckList(props);

  const { item, items, optional, shareable, uploadRef, activeOpportunity, loading, formSheet, fileUpload, optionsDocs, changeChecklistModalOpen, activeTaskList, activeChecklistName, taskLists, changeChecklistMessage, pullCreditModal, pullCredit, pullCreditProviders, ofacModalRef, idVerification, turnauto, cobuyerSignDocs, selectedCobuyerId, showParticipantModal, showModalMsg } = model;
  const { handleItemPress, closeModal, handleOptional, handleAction, handleFileSelected, reloadScreen, handleCloseForm, openPreview, handleOptionDoc, toggleChecklistModalOpen, changeActiveChecklist, handleFinishPullCredit, setPullCreditStatus, handlePullCredit, handleCheckProvider, handleVerified, handleTurnauto, setOfacStatus, handleOfac, handleCobuyerUpload, onCloseParticipant, onSubmitParticipant, onPopUpMsgClose } = operations;
  
  const upload = translate('checklist.shareable.upload');
  const remote = translate('checklist.shareable.remote', TextTransform.NONE, { interpolate: { action: item?.config.action.name } });
  const optionalMessage = item?.config.slug === 'co-signer' ? 'checklist.co-signer.description' : 'checklist.trade-in.description';

  const MsgIcon = () => (
    <IconContainer>
      <MIcon name="feedback" color={colors.ORANGE} size={26} />
    </IconContainer>
  );

  const CustomModalMsg = () => (
    <MsgContainer>
      <MsgText>
        {translate('popUpMsg')}
        <LearMoreButton
          onPress={() => {}}
        >
          <MsgText
            color={colors.URL_LINK}
            style={{ textDecorationLine: 'underline' }}
          >
            {translate('learnMore')}
          </MsgText>
        </LearMoreButton>
      </MsgText>
    </MsgContainer>
  );

  const renderItem = useCallback((info: ListRenderItemInfo<Task>) => (
    <TaskItem 
      info={info}
      TaskButton={<TaskButton info={info} onPress={handleItemPress} />}
    />
  ), [items, activeOpportunity]);

  if (loading) {
    return (
      <Root>
        <Flex flex={1} align='center' justify='center'>
          <ActivityIndicator color={colors.ORANGE} size="large" />
        </Flex>
      </Root>
    )
  }

  return (
    <Root>
      <ChecklistComplete />
      
      {!!activeChecklistName && taskLists && taskLists.length > 1 ? (
        <Flex paddingHorizontal={16} style={{ paddingTop: 16 }}>
          <ChecklistButton
            text={activeChecklistName}
            style={{ maxWidth: IS_DESKTOP_WEB && !IS_CHROME_EXTENSION && !IS_MOBILE_WEB ? 328 : '100%' }}
            onPress={toggleChecklistModalOpen}
          />
        </Flex>
      ) : null}

      <AddParticipantModal
        visible={showParticipantModal}
        onClose={onCloseParticipant}
        showModalMsg={showModalMsg}
        CustomModalMsg={(<CustomModalMsg />)}
        MsgIcon={(<MsgIcon />)}
        onMsgClose={() => onPopUpMsgClose()}
        leadId={activeOpportunity?.id!}
        onSubmit={onSubmitParticipant}
      />

      <FlatList
        data={items}
        refreshing={false}
        renderItem={renderItem}
        onRefresh={reloadScreen}
        ItemSeparatorComponent={() => <Spacer size={10} />}
        contentContainerStyle={{ paddingTop: 16, paddingBottom: 60 }}
        keyExtractor={(item, i) => item.id.toString() + i.toString()}
      />

      <OfacModal
        ref={ofacModalRef}
        name={activeOpportunity?.people.name ?? ''}
        onSuccess={() => setOfacStatus('success')}
        onDismiss={handleOfac}
      />
      
      <PullCreditModal
        ref={pullCreditModal}
        name={activeOpportunity?.people?.name ?? ""}
        onRetry={handlePullCredit}
        onDismiss={handleFinishPullCredit}
        onSuccess={() => setPullCreditStatus('success')}
      />
      
      <OfacModal
        ref={ofacModalRef}
        name={activeOpportunity?.people.name ?? ''}
        onSuccess={() => setOfacStatus('success')}
        onDismiss={handleOfac}
      />

      <ChecklistModal visible={shareable} onClose={closeModal}>
        <Button text={upload} onPress={() => handleAction?.('upload')} />
        <Button text={remote} onPress={() => handleAction?.('remote')} />
      </ChecklistModal>

      <ChecklistModal visible={optional} onClose={closeModal}>
        <Body3 link text={item?.config?.optionalMessage ?? translate(optionalMessage)} />

        <Button text={translate('yes')} onPress={() => handleOptional?.(true)} />
        <Button text={translate('no')} onPress={() => handleOptional?.(false)} />
      </ChecklistModal>

      <ChecklistModal visible={cobuyerSignDocs} onClose={closeModal}>
        {activeOpportunity?.participants?.map((participant) => (
          <Button text={`${translate('uploadForCobuyer')} ${participant.firstname} ${participant.lastname}`} onPress={() => handleCobuyerUpload(participant.id)} />
        ))}
      </ChecklistModal>

      <ChecklistModal visible={pullCredit} onClose={handleCloseForm}>
        <FlatList
          data={item?.config?.options ?? []}
          renderItem={({ item, separators, index }) => (
            <CheckboxItem
              item={{
                index: index,
                separators: separators,
                item: {
                  label: item.name,
                  value: item.slug,
                },
              }}
              selected={!!pullCreditProviders.find((op) => op === item.slug)}
              onSelect={() => handleCheckProvider(item.slug)}
            />
          )}
          keyExtractor={(item, index) => item.slug + index.toString()}
        />

        <Button text={translate('pullcredit.cta')} onPress={() => handlePullCredit()} disabled={pullCreditProviders.length <= 0} />
      </ChecklistModal>
      
      <ChecklistModal visible={idVerification} onClose={closeModal}>
        {!(IS_WEB || IS_DESKTOP_WEB || IS_CHROME_EXTENSION) && <Button text={translate('showroomId')} onPress={() => handleVerified?.(true)} />}
        <Button text={translate('remoteId')} onPress={() => handleVerified?.(false)} />
      </ChecklistModal>

      <ChecklistModal visible={turnauto} onClose={closeModal}>
        {!(IS_WEB || IS_DESKTOP_WEB || IS_CHROME_EXTENSION) && <Button text={translate('openTurnauto')} onPress={() => handleTurnauto?.(true)} />}
        <Button text={translate('completedTurnauto')} onPress={() => handleTurnauto?.(false)} />
      </ChecklistModal>

      <ChecklistModal visible={idVerification} onClose={closeModal}>
        {!(IS_WEB || IS_DESKTOP_WEB || IS_CHROME_EXTENSION) && <Button text={translate('showroomId')} onPress={() => handleVerified?.(true)} />}
        <Button text={translate('remoteId')} onPress={() => handleVerified?.(false)} />
      </ChecklistModal>

      <ChecklistModal visible={turnauto} onClose={closeModal}>
        {!(IS_WEB || IS_DESKTOP_WEB || IS_CHROME_EXTENSION) && <Button text={translate('openTurnauto')} onPress={() => handleTurnauto?.(true)} />}
        <Button text={translate('completedTurnauto')} onPress={() => handleTurnauto?.(false)} />
      </ChecklistModal>

      <ChecklistModal visible={optionsDocs.length > 0} onClose={handleCloseForm}>
        {
          optionsDocs.map((option) => {
            const { name, id } = option;
            return <Button key={`doc-${id}`} text={name} onPress={() => handleOptionDoc(option)} />;
          })
        }
      </ChecklistModal>

      <ChecklistModal visible={formSheet.length > 0} onClose={handleCloseForm}>
        {
          formSheet.map((action) => {
            const { name, id } = action;
            return <Button key={`subaction-${id}`} text={name} onPress={() => openPreview(item!, action)} />;
          })
        }
      </ChecklistModal>

      <UploadLeadFiles
        closeOnCapture
        ref={uploadRef}
        taskId={item?.id}
        onClose={handleFileSelected}
        opportunity={activeOpportunity!}
        defaultOption={item?.config?.item}
        peopleId={selectedCobuyerId}
      />

      <ChangeChecklistModal
        visible={changeChecklistModalOpen}
        onClose={toggleChecklistModalOpen}
        changeCheckList={changeActiveChecklist}
        message={changeChecklistMessage}
        taskLists={taskLists.filter(list => list.id.toString() !== activeTaskList?.activeTaskListId.toString())}
      />

      <Modal transparent visible={fileUpload} animationType='fade'>
        <SpinnerContainer>
          <Spinner size="small" color={colors.WHITE} />
          <Meta2 text={translate('uploading')} color={colors.WHITE} />
        </SpinnerContainer>
      </Modal>
    </Root>
  );
};

const Root = styled(Flex)`
  flex: 1;
  background-color: ${colors.WHITE};
`;

const SpinnerContainer = styled(Flex)
  .attrs((props) => (
    {
      ...props,
      gap: 8,
      flex: 1,
      row: true,
      align: 'center',
      justify: 'center',
    }
  ))`
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  `;

const MsgContainer = styled.View`
  height: 100%;
  flex-direction: row;
  width: 80%;
  align-items: center;
`;

const MsgText = styled.Text<any>`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color || colors.ICON_PRIMARY};
  font-family: ${FONTS.SFProText};
`;

const IconContainer = styled.View`
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
`;

const LearMoreButton = styled.TouchableOpacity`
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: -3px;
`;


const Button = styled(ButtonWithIcon)
  .attrs((props) => (
    { 
      ...props,
      type: 'button1',
      color: colors.WHITE,
      textColor: colors.TEXT_ORANGE_LINK 
    }
  ))``;