import { TaskResponse } from "model/api/tasks/queries";

import { LeadAction } from "domain/shared/types/lead-action";
import { INSURANCE_FORM, MILEAGE_CONFIRMATION_FORM, PAYOFF_ID_FORM, SOLD_CARD_FORM } from "domain/shared/screens";

export enum Status {
  PENDING = 'pending',
  COMPLETED = 'completed',
  NO_APPLICABLE = 'no_applicable',
  IN_PROGRESS = 'in_progress',
}

export enum Type {
  DEFAULT,
  CAMERA,
  FORM,
  LIST,
  PULL_CREDIT,
  OFAC,
  ID_VERIFICATION,
  TURNAUTO
}

export type OptionDoc = {
  id: number;
  name: string;
}

type Config = {
  slug: string,
  
  item?: string,
  screen?: string;
  optional?: boolean;
  optionalMessage?: string;
  cobuyerSignDocs?: boolean;
  optionsDocs?: OptionDoc[];
  direct?: boolean;
  messageUuid?: string;
  iframe_url_signed?: string;
  disabled: boolean;
  inactive: boolean;

  action: LeadAction;
  options?: LeadAction[];
}


const FORMS = ['credit-app', 'co-signer', 'payoff-verification', 'insurance-verification', 'sold-car-verification']
const LIST = ['get-docs', 'cc-auth', 'csi-approval', 'credit-consent', 'purchase-vehicle', 'esign-docs'];

export default class Task {
  constructor(
    readonly id: number,
    readonly title: string,
    readonly type: Type,
    readonly status: Status,
    readonly config: Config,
  ) {
  }

  static fromGraph(task: TaskResponse) {
    const { name, description, form_config } = task.company_action;
    const { slug } = task.company_action.action;

    const type = Task.getType(slug);
    const status = Task.getStatus(task.status);
    const screen = Task.getScreen(slug);
    
    let { id: item = undefined, options = undefined, optionsDocs = undefined, disabled = false, inactive = false } = task.config || {}; 

    const optional = !!task.config?.optional_required_message || ['co-signer', 'add-trade', 'payoff-verification'].includes(slug);
    const direct = ['esign-docs'].includes(slug);

    if (slug === 'upload-docs' && item === undefined) item = 'score-disclosure';

    const action = {
      ...task.company_action.action,
      id: task.company_action.id,
      name,
      description,
      form_config,
    }

    const config = { 
      slug,
      action,
      item,
      options,
      optional,
      optionalMessage: task.config?.optional_required_message,
      optionsDocs,
      direct,
      screen,
      messageUuid: task.engagement_end?.message.uuid ?? undefined,
      cobuyerSignDocs: task.config?.cobuyer_dl ?? false,
      iframe_url_signed: task.engagement_end?.message?.message?.data?.iframe_url_digital_jacket ?? undefined,
      disabled,
      inactive
    };

    return new Task(task.id, task.name, type, status, config);
  }

  private static getType(slug: string): Type {
    if (slug === 'upload-docs') return Type.CAMERA;
    if (slug === '700-credit') return Type.PULL_CREDIT;
    if (FORMS.includes(slug)) return Type.FORM;
    if (LIST.includes(slug)) return Type.LIST;

    if (slug === 'ofac') return Type.OFAC;
    if (slug === 'id-verification') return Type.ID_VERIFICATION;
    if (slug === 'turnauto') return Type.TURNAUTO;
    
    return Type.DEFAULT;
  }

  private static getStatus(status: string): Status {
    if (status === 'completed') return Status.COMPLETED;
    if (status === 'no_applicable') return Status.NO_APPLICABLE;
    return Status.PENDING
  }

  private static getScreen(slug: string): string | undefined {
    const strategy: Record<string, string | undefined> = {
      'payoff-verification': PAYOFF_ID_FORM, 
      'insurance-verification': INSURANCE_FORM, 
      'sold-car-verification': SOLD_CARD_FORM,
      'mileage-confirmation': MILEAGE_CONFIRMATION_FORM,
      'default': undefined
    }

    return strategy[slug] || strategy.default;
  }
}