/* eslint-disable react/static-property-placement */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/sort-comp */
/* eslint-disable react/prop-types */
import React, { Component, useCallback } from 'react';

import {
  Keyboard,
  TextInput,
  Pressable,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import GetToken from 'domain/shared/utils/auth/async-storage';


import { sendAmplitudeEvent } from 'domain/shared/utils/amplitude';
import OneSignal from 'domain/shared/utils/onesignal'; // Import package from node modules

import { Flex, Spacer } from '@kanvas/andromeda';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Toast from 'react-native-toast-message';
import Button from 'atoms/button-with-icon';

import CRMExtensionService from 'domain/shared/services/crm-extension-service';
import {
  DEFAULT_COMPANY_INFO_KEY,
  GRAPH_TOKEN_KEY,
  IS_WEB,
  USER_TOKEN_KEY,
} from 'domain/shared/constants';
import { LOGIN } from 'domain/shared/types/amplitude-actions';
import AuthContext from 'components/context/auth';
import UserService from 'domain/shared/services/user-service';
import useAuth from 'domain/shared/hooks/useAuth';
import Spinner from 'components/atoms/spinner';
import { showError } from 'domain/shared/utils/toast';
import { User } from 'domain/shared/types/user';
import Input from 'components/atoms/text-input';
import { needUpdate } from 'domain/shared/hooks/use-update';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import colors from 'theme/colors';
import GraphUserService, { Me } from 'domain/shared/services/graph-user-service';
import { MainScreen } from 'domain/screens';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'domain/shared/store';
import { setMe } from 'domain/shared/store/slice/auth';
import { SET_UP_2_FACTOR } from 'domain/shared/screens';
import SaBlackLogo from 'assets/svgs/sa-black-logo';
import TabNavigationScreens from 'domain/shared/navigation/tab-nav-screens';
import { AvailableFeatures } from 'domain/shared/types/available-features';
import BrutalService from 'domain/shared/services/brutal.service';
import { TextTransform } from 'react-native-localized-text';
import { translate } from 'locales';
import { Body3, Header3 } from 'components/molecules/text';
import LinkButton from 'components/atoms/link-button';

interface IProps {
  navigation: any;
  logOut: () => void;
  setCurrentUser: (user: User) => void;
  setMe: (me: Me) => void;
}

interface IState {
  data: {
    email: string;
    password: string;
  },
  error: any;
  isLoading: boolean;
  isLoggedUser: boolean;
  isCollapseLogin: boolean;
  oneSignalPlayerId: string;
  email: string;
  password: string;
  isPasswordSecure: boolean
  features?: AvailableFeatures;
  forgotPassword: boolean,
  sendEmailToReset: boolean,

}

const withUseAuthHook = (Component: any) => (
  (props: any) => {
    const { logOut, setAuthenticatedUser } = useAuth();
    const dispatch = useDispatch<AppDispatch>();

    const setUser = useCallback((user: User) => {
      setAuthenticatedUser(user);
    }, []);

    const setAuthMe = (me: Me) => dispatch(setMe(me));

    return (
      <Component
        logOut={() => logOut(false)}
        setCurrentUser={setUser}
        setMe={setAuthMe}
        {...props}
      />
    );
  }
);

class Login extends Component<IProps, IState> {
  static contextType = AuthContext;

  pwdInput: React.RefObject<TextInput>;

  constructor(props: IProps) {
    super(props);

    this.state = {
      data: {
        email: '',
        password: '',
      },
      error: null,
      isLoading: false,
      isLoggedUser: true,
      isCollapseLogin: false,
      oneSignalPlayerId: '',
      email: '',
      password: '',
      isPasswordSecure: true,
      features: undefined,
      forgotPassword: false,
      sendEmailToReset: false,
    };

    this.pwdInput = React.createRef<TextInput>();
  }

  async componentDidMount() {
    const { navigation } = this.props;

    // TODO: refactor this section to use api call 
    const [need, features] = await Promise.all([
      needUpdate(),
      BrutalService.features(),
    ]);

    if (!IS_WEB && (features.updater && need)) {
      navigation.replace(MainScreen.Update);
      return;
    }

    this.getUserInfo();
    this.setState({ isLoading: false, features });
  }

  getUserInfo = async () => {
    try {
      const { logOut } = this.props;

      const [token, graphToken, defaultCompanyUUID] = await Promise.all([
        GetToken(),
        GetToken(GRAPH_TOKEN_KEY),
        GetToken(DEFAULT_COMPANY_INFO_KEY),
      ]);

      if (token && graphToken.auth && defaultCompanyUUID) {
        this.isLoggedUser(token);
      } else {
        logOut();
        this.setState({ isLoggedUser: false });
      }
    } catch (error) {
      // this.props.changeLeadsInfo(null);
      this.setState({ isLoggedUser: false });
    }
  };

  onChangeValue = (value: string, property: string) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [property]: value,
      },
    }));
  };

  async isLoggedUser({ token }: any) {
    try {
      const user = await UserService.getUser(token.id);


      this.props.setCurrentUser(user);
      this.onesignalRegister(user.id);
      this.notifyLogin(user);
      return this.navigate();
    } catch (error) {
      const { logOut } = this.props;
      logOut();
      this.setState({ isLoggedUser: false });
    }
  }

  async proceedLogin() {
    try {
      const { email, password } = this.state.data;
      const oneSignalPlayerId = await OneSignal.getDeviceState();

      const [auth, graphAuth] = await Promise.all([
        UserService.authenticate(email, password),
        UserService.authenticateGraph(email, password, oneSignalPlayerId!.userId),
      ]);

      // handle graph auth lock user error
      if (graphAuth.hasError && graphAuth.error_type !== 'InvalidEmailPassword') {
        this.setState({ isLoading: false });
        return Toast.show({
          text1: graphAuth?.message,
          type: 'error',
        });
      }

      if (!auth) throw new Error("No auth login information");
      if (!Object.keys(graphAuth!).length) throw new Error('NO Graph login information');

      const { id: userId } = auth;

      const [_, __, user] = await Promise.all([
        await AsyncStorage.setItem(USER_TOKEN_KEY, JSON.stringify({ token: auth })),
        await AsyncStorage.setItem(GRAPH_TOKEN_KEY, JSON.stringify({ auth: graphAuth })),
        await UserService.getUser(0),
      ]);

      await AsyncStorage.setItem(DEFAULT_COMPANY_INFO_KEY, JSON.stringify({
        default_company_uuid: user.default_company_uuid,
        default_company_branch_uuid: user.default_company_branch_uuid,
      }));

      this.onesignalRegister(userId);
      this.props.setCurrentUser(user);
      this.navigate();
      this.notifyLogin(user);
    } catch (error: any) {
      console.log(error);

      const defaultErrorMessage =
        "We couldn't log you in. Please check your email and password and try again.";

      this.setState({ isLoading: false }, () => showError(defaultErrorMessage));
    }
  }

  navigate = async () => {
    try {
      const { navigation } = this.props;
      const { features } = this.state;
      const me = await GraphUserService.me();

      this.props.setMe(me);
      const checkTwoFactor = me.verify_two_factor && features!.twofa;
      const screen = checkTwoFactor ? SET_UP_2_FACTOR : TabNavigationScreens.STACK_NAME;
      navigation.navigate(screen, { me });
    } catch {
      const { logOut } = this.props;
      logOut();
      this.setState({ isLoggedUser: false });
    }
  };

  notifyLogin(user: User) {
    sendAmplitudeEvent(LOGIN, { event: LOGIN });
    if (!IS_WEB) return;
    // Used to authenticate amplitude on chrome extension
    CRMExtensionService.setAuthenticatedUser(user);
    CRMExtensionService.onExtensionLoad();
  }

  onesignalRegister(userId: number) {
    OneSignal.getDeviceState().then((deviceState) => {
      if (deviceState) {
        const { userId: deviceId } = deviceState;
        if (deviceId) {
          UserService.registerUserDevice(userId, deviceId);
        }
      }
    });
  }

  async forgotPasswordForm(value:boolean) {
    this.setState({ forgotPassword: value, data: { email: '', password: '' } });
  }

  async forgotPasswordHandle() {
    if (!this.state.sendEmailToReset) {
      this.setState({ isLoading: true });
      await UserService.forgotPassword(this.state.data.email).finally(() => {
        this.setState({ sendEmailToReset: true, isLoading: false });
      })

      return
    }

    if (this.state.sendEmailToReset) {
      this.setState({ sendEmailToReset: false, forgotPassword: false });
    }
  }

  async singIn() {
    Keyboard.dismiss();

    if (!this.state.data.email.trim() || !this.state.data.password.trim()) {
      return Toast.show({
        text1: 'Please fill email/username and password fields.',
        type: 'error',
      });
    }

    return this.setState({ isLoading: true }, async () => await this.proceedLogin());
  }

  render() {
    if (this.state.isLoggedUser) {
      return (
        <Flex justify="center" align="center" style={{ width: '100%', height: '100%' }}>
          <Spinner />
        </Flex>
      );
    }
    return (
      <Flex>
        <KeyboardAwareScrollView contentContainerStyle={{ height: '100%' }}>
          <Flex paddingHorizontal={28}>
            <Flex
              justify="center"
              // align="center"
              style={{ height: '100%' }}
            >
              <Flex justify="center" align="center">
                <Flex justify="center" align="center" style={{ flexGrow: 1 }}>
                  <SaBlackLogo />
                </Flex>
                {!this.state.forgotPassword && (
                <Flex gap={10} style={{ flexGrow: 2, width: '100%' }}>
                  <Input
                    autoCapitalize="none"
                    placeholder="Email or Username"
                    returnKeyType="next"
                    maxLength={50}
                    keyboardType="email-address"
                    returnKeyLabel="Next"
                    value={this.state.data.email}
                    onChangeText={(value) => this.onChangeValue(value, 'email')}
                    onSubmitEditing={() => this.pwdInput.current?.focus()}
                    testID="txtEmail"
                    textContentType="oneTimeCode"
                  />
                  <Flex row>
                    <Flex style={{ position: 'relative', flex: 1 }}>
                      <Input
                        autoCapitalize="none"
                        placeholder="Password"
                        value={this.state.data.password}
                        keyboardType="default"
                        returnKeyLabel="go"
                        returnKeyType="go"
                        secureTextEntry={this.state.isPasswordSecure}
                        onChangeText={(value: string) => this.onChangeValue(value, 'password')}
                        onSubmitEditing={() => this.singIn()}
                        testID="txtPassword"
                        ref={this.pwdInput}
                        textContentType="oneTimeCode"
                        style={{ paddingRight: 40 }}
                      />
                      <Pressable
                        style={{ position: 'absolute', right: 15, top: 8 }}
                        onPress={() => this.setState(({ isPasswordSecure }) => ({
                          isPasswordSecure: !isPasswordSecure,
                        }))}
                      >
                        <MaterialCommunityIcons
                          name={this.state.isPasswordSecure ? 'eye-off' : 'eye'}
                          size={25}
                          color={colors.TEXT_PLACEHOLDER}
                        />
                      </Pressable>
                    </Flex>
                  </Flex>
                  <Spacer size={8} />
                  {this.state.isLoading ? (
                    <Flex justify="center" align="center">
                      <Spinner />
                    </Flex>
                  ) : (
                    <Button
                      onPress={() => this.singIn()}
                      text="Log In"
                    />
                  )}
                </Flex>
                )}
                {this.state.forgotPassword && (
                <Flex gap={4} style={{ flexGrow: 1, width: '100%' }}>
                  <Flex marginVertical={15}>
                    <Header3
                      text={translate('forgotPassword', TextTransform.CAPITAL)}
                    />

                    {!this.state.sendEmailToReset && <Body3 text={translate('forgot-password.subtitle')} />}
                  </Flex>
                  { !this.state.sendEmailToReset
                    ? (
                      <Input
                        autoCapitalize="none"
                        placeholder="Username"
                        returnKeyType="next"
                        maxLength={50}
                        keyboardType="email-address"
                        returnKeyLabel="Next"
                        value={this.state.data.email}
                        onChangeText={(value) => this.onChangeValue(value, 'email')}
                        onSubmitEditing={() => this.pwdInput.current?.focus()}
                        testID="txtEmail"
                        textContentType="oneTimeCode"
                      />
                    )
                    : (
                      <Body3 text={translate('forgotPasswordMessage', TextTransform.CAPITAL)} />
                    )}
                  <Spacer size={8} />
                  <Button
                    onPress={() => this.forgotPasswordHandle()}
                    text={translate((!this.state.sendEmailToReset ? 'submit' : 'backToLogin'), TextTransform.CAPITAL)}
                    color={!this.state.sendEmailToReset ? colors.ORANGE : colors.WHITE}
                    textColor={!this.state.sendEmailToReset ? colors.WHITE : colors.ORANGE}
                    loading={this.state.isLoading}
                  />
                </Flex>
                )}
                {(!this.state.sendEmailToReset && !this.state.isLoading) && (
                <Flex marginVertical={20} align="center" justify="center">
                  <LinkButton
                    text={translate((!this.state.forgotPassword ? 'forgotPassword' : 'back'), TextTransform.CAPITAL)}
                    color={colors.TEXT_ORANGE_LINK}
                    onPress={() => this.forgotPasswordForm(!this.state.forgotPassword)}
                    size={15}
                  />
                </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </KeyboardAwareScrollView>
      </Flex>
    );
  }
}

export default withUseAuthHook(Login);
