import { Flex } from '@kanvas/andromeda';
import { useNavigation } from '@react-navigation/native';
import ButtonWithIcon from 'components/atoms/button-with-icon';
import { Header2 } from 'components/molecules/text';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-native-modal';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';

import colors from 'theme/colors';
import { FONTS } from 'theme/typography';
import { RootState } from 'domain/shared/store';
import { IS_MOBILE, IS_WEB } from 'domain/shared/constants';
import { useEffectOnce } from 'react-use';
import { EventRegister } from 'react-native-event-listeners';
import { OverLayEvents } from 'domain/shared/utils/cx-overlay';
import { CXTabBarContext } from '../CXTabBar/cx-tab-bar-context';

const button = 'Continue with this Closed Lead';

export default function CloseLeadBanner() {
  const [open, setOpen] = useState(false);
  const navigation = useNavigation();

  const lead = useSelector((state: RootState) => state.opportunity.activeOpportunity);

  const { status_name = undefined } = lead || {};
  const { CRMhost } = useContext(CXTabBarContext);
  

  const handlePress = () => setOpen(false);

  useEffect(() => {
    if (CRMhost !== 'vinSolutions') return;
    
    if (!status_name) return () => setOpen(false);

    const isActive = status_name.toLowerCase() === 'active';
    setOpen(!isActive);
  }, [status_name, CRMhost]);

  useEffectOnce(() => {
    const event = EventRegister.addEventListener(OverLayEvents.SHOW, () => {
      setOpen(false);
    })

    return () => {
      EventRegister.removeEventListener(event.toString());
      setOpen(false);
    }
  });

  return (
    <Modal isVisible={open}>
      <Container gap={16}>
        <Title />

        {IS_MOBILE && <GoBack onPress={() => navigation.goBack()} />}
        <Button onPress={handlePress} />
      </Container>
    </Modal>
  );
}

function Title() {
  if (IS_MOBILE) return <Header2 text='This is a Closed Lead.' />

  return (
    <Text>
      <Text>This is a </Text>
      <B>Closed Lead. </B>
      <Text>Please click on the </Text>
      <B>Active Lead </B>
      <Text>of the customer in the CRM (if any) and then open SalesAssist Extension.</Text>
    </Text>
  );
}

const Container = styled(Flex).attrs((props) => ({ ...props, animationIn: 'slideInUp', animationOut: 'slideOutDown' }))`
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  background-color: ${colors.WHITE};
  ${IS_WEB ? `box-shadow: 0px -4px 16px ${colors.BUTTON_BORDER}` : ''};
`;

const Text = styled.Text.attrs((props) => ({ ...props, allowFontScaling: false }))`
  font-size: 17px;
  line-height: 20px;
  font-family: ${FONTS.SFProText};
  color: ${colors.ICON_PRIMARY}
`;

const B = styled(Text)`
  font-family: ${FONTS.SFProTextSemiBold}
`;

const GoBack = styled(ButtonWithIcon).attrs((props) => ({  ...props, text: 'Go Back' }))``;

const Button = styled(ButtonWithIcon)
  .attrs((props) => ({ 
    ...props,
    text: button,
    color: colors.WHITE,
    textColor: colors.TEXT_ORANGE_LINK 
  }))``;