/* eslint-disable react/no-unused-prop-types */

// Modules
import React, { useState } from 'react';
import DropDownPicker, { ListModeType } from 'react-native-dropdown-picker';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { StyleSheet } from 'react-native';

import Colors from 'theme/colors';
import { Dropdown } from 'react-native-element-dropdown';

const styles = StyleSheet.create({
  dropdown: {
    backgroundColor: Colors.WHITE,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.BORDER_INPUT,
    shadowColor: Colors.BUTTON_BORDER,
    shadowOpacity: 1,
    shadowOffset: { width: 4, height: 5 },
    shadowRadius: 8,
    paddingHorizontal: 12,
    position: 'relative',
    zIndex: 0,
    height: 40,
    minHeight: 40,
  },
  icon: {
    marginRight: 5,
  },
  placeholderStyle: {
    fontSize: 14,
    color: Colors.TEXT_PLACEHOLDER,
  },
  selectedTextStyle: {
    fontSize: 14,
    color: Colors.BLACK,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  itemContainerStyle: {
    padding: 0,
    marginVertical: -5,
  },
  itemTextStyle: {
    fontSize: 14,
    color: Colors.GREY,
  },
});

interface IProps {
  items: any,
  onSelectItem: (item: any) => void,
  value: any,
  placeHolder: string,
  zIndex?: number,
  zIndexInverse?: number,
  disabled?: boolean;
  indicator?: boolean
  disableLocalSearch?: boolean
  onChangeSearchText?: (text: string) => void,
  validate?: string
  errorsRefs?: any;
  property?: string;
  searchable?: boolean
  listMode?: ListModeType
}
const DropDown = (props: IProps) => {
  const {
    items,
    onSelectItem,
    value,
    placeHolder,
    disabled = false,
  } = props;

  const [open, setOpen] = useState(false);
  const [values, setValue] = useState(value);

  return (
    <Dropdown
      style={styles.dropdown}
      placeholderStyle={styles.placeholderStyle}
      selectedTextStyle={styles.selectedTextStyle}
      inputSearchStyle={styles.inputSearchStyle}
      iconStyle={styles.iconStyle}
      data={items}
      disabled={disabled}
      maxHeight={300}
      labelField="label"
      valueField="value"
      placeholder={placeHolder}
      value={value}
      onChange={onSelectItem}
      itemContainerStyle={styles.itemContainerStyle}
      itemTextStyle={styles.itemTextStyle}
      onFocus={() => setOpen(true)}
      onBlur={() => setOpen(false)}
      renderRightIcon={() => <Icon name={open ? 'menu-up' : 'menu-down'} size={24} color={Colors.BLACK} />}
    />
  );
};

export default DropDown;