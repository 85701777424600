/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import styled from 'styled-components/native';
import { RectButton, ScrollView } from 'react-native-gesture-handler';
import Colors from 'theme/colors';
import { Body3, Button2 } from 'components/molecules/text';
import { Flex, Spacer } from '@kanvas/andromeda';
import Icon from 'react-native-vector-icons/MaterialIcons';
import ActionService from 'domain/shared/services/action-service';
import { useNavigation } from '@react-navigation/native';
import { FORM } from 'domain/shared/screens';
import { connect, useSelector } from 'react-redux';
import ListTitle from 'components/molecules/list-title';
import { EventRegister } from 'react-native-event-listeners';
import { RootState } from 'domain/shared/store';
import ButtonList, { ButtonListItem, RenderItemFn } from '../button-list';
import { ActionTypes } from 'domain/opportunity-wall/hooks/use-doc-list';
import { useAsync, useAsyncFn } from 'react-use';
import CustomFieldsApi from 'domain/shared/services/custom-fields';

export const CLOSE_EXPANDED_CHECK_LIST = 'CLOSE_EXPANDED_CHECK_LIST';
const PREFILL_SIGN_DOCS = 'prefill-sign-docs';

interface IProps {
  onChange?: (items: ButtonListItem[]) => void;
  items: ButtonListItem[];
  company_id?: string,
  lead_id?: string,
  expandable?: boolean;
  notScrollable?: boolean;
  text?: string;
  preFillSelected?: boolean;
  action: string;
}

const Container = styled(Flex)`
  padding: 16px;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.DIVIDER};
  flex-direction: row;
  width: 100%;
`;

const Button = styled(RectButton)`
  flex: 1;
`;

const styles = StyleSheet.create({
  contentContainerStyle: { paddingBottom: 40 },
});

interface IPropsPreFill {
  loading?: boolean;
  handle: () => void
}
const OPTION_GROUP: string = 'option-group';
// const DATEPICKER: string = 'datepicker';
// const MASKED_INPUT: string = 'masked-input';

const Prefill = (props: IPropsPreFill) => {
  const { handle, loading } = props;

  if (loading) {
    return <ActivityIndicator size="small" color={Colors.ORANGE} />
  }

  return (
    <Button2
      text="Prefill"
      color={Colors.ORANGE}
      onPress={handle}
      align="right"
    />
  );
};

function ContainerRenderer(props: { notScrollable: boolean, children: ReactNode }) {
  const { notScrollable, children } = props;
  if (notScrollable) return <>{children}</>;
  return (
    <ScrollView
      contentContainerStyle={styles.contentContainerStyle}
    >
      {children}
    </ScrollView>
  );
}

const CheckList = (props: IProps) => {
  const {
    onChange,
    items,
    company_id,
    lead_id,
    expandable,
    notScrollable,
    text,
    preFillSelected = false,
    action,
  } = props;
  const [pdfs, setPdf] = useState<[]>();
  const [linkedFills, setLinkedFils] = useState({}) as any;
  const [currentLoading, setLoading] = useState(false);
  const [expand, setExpand] = useState(false);
  
  const navigation = useNavigation() as any;
  const lead = useSelector((state: RootState) => state.opportunity.activeOpportunity)!;

  useEffect(() => {
    const exist = items.find(item => item.selected);
    setExpand(!!exist);
  }, [items])

  const [prefillSingDocs, deFetch] = useAsyncFn(async () => {
    const { customFields } = await CustomFieldsApi.get({ entity_id: lead.uuid, name: PREFILL_SIGN_DOCS });    
    return customFields[PREFILL_SIGN_DOCS];    
  }, [lead]);

  const loading = currentLoading && prefillSingDocs.loading;

  const displayOptions = useMemo(() => {
    if (!expandable) return true;
    return expand;
  }, [expandable, expand]);

  const getDocumentsPdf = async () => {
    const enableActions = [ActionTypes.EsignDocs, ActionTypes.PurchaseVehicle, ActionTypes.LoanerCarAgreement, ActionTypes.FinanceInsurance];
    if (!enableActions.includes(action)) return;
    
    const response = await ActionService.getActionDocs(company_id, action);
    const { customer_pdfs = [] } = response.form_config || {};
    setPdf(customer_pdfs);
  };

  const getLinkedFields = async () => {
    setLoading(true);
    const response = await ActionService.getLinkedFields(lead_id, company_id);
    if (response) setLinkedFils(response);
    setLoading(false);
  };

  const getFieldsFromFormSchema = useCallback((form_schema: any, initial_values: any = {}) => {
    const prefillKeys = (prefillSingDocs.value) ? Object.keys(prefillSingDocs.value) : undefined;

    const fields = Object.entries(form_schema).map((field) => {
      const obj: any = field[1];
      const {
        type,
      }: any = obj;
      const { attrs: { linked_field, backgroundSync = '' } } = obj;

      const prop_name = linked_field;
      const show = ['rooftop.registration-fees', 'rooftop.conveyance-fee', 'prefill.vin-etch-value'];
      const prePropulated = show.includes(linked_field) ? false : linkedFills[linked_field];

      if (prefillKeys?.includes(linked_field)) {
        return {
          prop_name,
          ...obj,
          type,
        } as any;
      }

      if (obj?.prefill_attrs?.hidden) return undefined;

      if (type === OPTION_GROUP) {
        const value = initial_values[prop_name];
        if (!value) {
          return {
            prop_name,
            ...obj,
            type,
          } as any;
        }
      }

      const shouldShow = (prePropulated === null || prePropulated === false) || type === "search-stock";
      if (shouldShow && (type !== OPTION_GROUP) && !backgroundSync) {
        return {
          prop_name,
          ...obj,
          type,
        } as any;
      }
      return undefined;
    }).filter((a) => a !== undefined);
    return fields;
  }, [linkedFills, pdfs, prefillSingDocs]);

  useEffect(() => {
    deFetch();
    getLinkedFields();
    const event = EventRegister.addEventListener(CLOSE_EXPANDED_CHECK_LIST, () => {
      setExpand(false);
    }).toString();

    return () => {
      EventRegister.removeEventListener(event);
    };
  }, []);

  useEffect(() => {
    getDocumentsPdf();
  }, [linkedFills]);

  const renderItem: RenderItemFn = useCallback((
    item,
    onItemPress,
    enabled,
  ) => {
    const textColor = enabled ? Colors.PRIMARY_TEXT : Colors.DISABLED;
    const iconName = item.selected ? 'check-box' : 'check-box-outline-blank';
    const iconColor = item.selected
      ? Colors.DARK_ORANGE : enabled
        ? Colors.SECONDARY_TEXT : Colors.DISABLED;

    const validateHasField = (formId: number) => {
      if (pdfs && Object.keys(linkedFills).length > 0) {
        const file = pdfs.find(({ id }: any) => id === formId);
        if (file) {
          const { form_schema = {} } = file;
          if (form_schema) {
            const fields = getFieldsFromFormSchema(form_schema);
            if (fields.length > 0) {
              return true;
            }
            return false;
          }
        }
      }
      return false;
    };

    const refresh = () => deFetch();

    const showPreFillForm = async (formId: number, fills = {}) => {
      const file = pdfs.find(({ id }: any) => id === formId);

      if (file) {
        const {
          form_schema = {},
          title = '',
          id = 0,
          initial_values,
        } = file;

        if (form_schema) {
          const fields = getFieldsFromFormSchema(form_schema, initial_values);

          if (fields) {
            navigation.navigate(FORM, {
              title,
              fields,
              id,
              linkedFills: fills,
              prefillSingDocs: prefillSingDocs.value,
              refresh,
            });
          }
        }
      }
    };

    return (
      <React.Fragment key={item.key}>
        <Container>
          <Flex flex={1} row align="center">
            <Button
              enabled={enabled}
              onPress={() => onItemPress(item)}
            >
              <Flex row>
                {!preFillSelected && <Icon name={iconName} color={iconColor} size={24} />}
                <Spacer horizontal size={4} />
                <Body3 text={item.text?.trim()} color={textColor} />
              </Flex>
            </Button>
          </Flex>
          {
            !preFillSelected ? (
              <Flex>
                {(item.selected && validateHasField(item.value))
              && (<Prefill loading={loading} handle={() => showPreFillForm(item.value, linkedFills)} />)}
              </Flex>
            )
              : (
                <Flex>
                  {(validateHasField(item.value))
              && (<Prefill loading={loading} handle={() => showPreFillForm(item.value, linkedFills)} />)}
                </Flex>
              )
         }
        </Container>
      </React.Fragment>
    );
  }, [linkedFills, pdfs, prefillSingDocs]);

  return (
    <ContainerRenderer notScrollable={!!notScrollable}>
      {notScrollable && (
        <ListTitle
          expand={expand}
          text={text || ''}
          expandable={expandable}
          onExpand={() => setExpand((c) => !c)}
        />
      )}

      {(displayOptions && items.length > 0) && (
        <ButtonList
          items={items}
          onItemSelected={onChange}
          renderItem={renderItem}
          multiselect
        />
      )}
    </ContainerRenderer>
  );
};
const mapStateToProps = (state: RootState) => ({
  company_id: state.auth.user?.default_company_uuid,
  lead_id: state.opportunity.activeOpportunity?.uuid,
});

export default connect(mapStateToProps, null)(CheckList);
